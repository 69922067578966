
/* Custom styles for the dropdown */
.custom-dropdown {
  position: relative;
  display: inline-block;
}

/* Style the select element */
.custom-dropdown select {
  background-color: #ffffff;
  border: 1px solid #ced4da;
  padding: 5px 10px; /* Adjust padding as needed */
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

/* Style the dropdown options */
.custom-dropdown select option {
  background-color: #ffffff;
  color: #000000;
}

/* TextColorPicker.css */

.text-color-picker {
  position: relative;
}

.color-button {
  padding: 5px 10px;
  background-color: transparent;
  border: 1px solid #ccc;
  cursor: pointer;
}

.color-picker-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1000;
}

.color-option {
  width: 20px;
  height: 20px;
  margin: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.selected-color {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  border-radius: 50%;
}
