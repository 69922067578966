@import url("https://fonts.googleapis.com/css2?family=Allerta+Stencil&family=Familjen+Grotesk:wght@400;500;600;700&family=Inter:wght@300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  /* font-family: Raleway, sans !important; */
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.scrollbar-hide::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.activeSetting {
  background-color: #d0d7de3d;
}

@media only screen and (max-width: 600px) {
  .flip-image {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1) rotate(10deg);
  }
}

@media only screen and (max-width: 820px) {
  .hide-sidebar {
    display: none;
  }
}

@media only screen and (max-width: 920px) {
  .md-width {
    width: 60vw;
  }
}

@media only screen and (max-width: 820px) {
  .sm-width {
    width: 100vw;
  }
}

.fade-container {
  position: relative;
  width: fit-content;
}

.fade-container img {
  position: absolute;
  width: 100%;
  height: auto;
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.select-custom {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #d0d7de;
  border-radius: 0.75rem !important;
  font-size: 16px;
  padding: 0.25rem auto;
  color: #000;
  cursor: pointer;
}

.test {
  color:#000;
}
